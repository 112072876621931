import { gql } from "@apollo/client";

// -------------------------------------------------- PURHASE ORDER MUTATIONS ----------------------------------------------------------------------------
export const IMPORT_PO_MAP_EXCEL_HEADERS_MUTATION = gql`
  mutation ImportPoMapExcelHeaders($file: Upload!) {
    importPoMapExcelHeaders(file: $file) {
      ExcelHeaders
      DatabaseColumns {
        DbColumnName
        DbColumnLabel
      }
    }
  }
`;

export const CHECK_PO_HEADERS_IN_SCOPE = gql`
  mutation checkPoHeadersInScope($headerMappingInput: HeaderMappingInput!) {
    checkPoHeadersInScope(headerMappingInput: $headerMappingInput)
  }
`;

export const REMOVE_ALL_PURCHASE_ORDER_MUTATION = gql`
  mutation deleteAllPoItemsAndDocs($purchaseOrdersList: [CreatePurchaseOrderDetails!]!){
    deleteAllPoItemsAndDocs(
      purchaseOrdersList: $purchaseOrdersList
    )
  }
`;

export const CANCEL_SELECTED_PURCHASE_ORDERS_MUTATION = gql`
  mutation cancelSelectedPurchaseorders(
    $purchaseOrdersListForCancel: [CreatePurchaseOrderForCancel!]!
  ){
    cancelSelectedPurchaseorders(
      purchaseOrdersListForCancel: $purchaseOrdersListForCancel
    )
  }
`;

export const REMOVE_IMPORTED_PURCHASE_ORDER_MUTATION = gql`
  mutation deleteImportedPoItemsAndDocs($purchaseOrdersList: [CreatePurchaseOrderDetails!]!){
    deleteImportedPoItemsAndDocs(
      purchaseOrdersList: $purchaseOrdersList
    )
  }
`;

// -------------------------------------------------- PURHASE ORDER ITEM MUTATIONS ----------------------------------------------------------------------------
export const PO_ITEM_CREATE = gql`
  mutation createPurchaseOrderItem(
    $createPurchaseOrderItemInput: CreatePurchaseOrderItemInput!
  ) {
    createPurchaseOrderItem(
      createPurchaseOrderItemInput: $createPurchaseOrderItemInput
    ) {
      id
    }
  }
`;

export const PO_ITEM_UPDATE = gql`
  mutation updatePurchaseOrderItem(
    $updatePurchaseOrderItemInput: UpdatePurchaseOrderItemInput!
  ) {
    updatePurchaseOrderItem(
      updatePurchaseOrderItemInput: $updatePurchaseOrderItemInput
    ) {
      id
    }
  }
`;

export const PO_ITEM_EDIT_ROW_UPDATE = gql`
  mutation updatePurchaseOrderItemEditRow(
    $updatePurchaseOrderItemInput: EditRowOfPurchaseOrderItem!
  ) {
    updatePurchaseOrderItemEditRow(
      updatePurchaseOrderItemInput: $updatePurchaseOrderItemInput
    ) {
      id
    }
  }
`;

export const PO_ITEM_REMOVE = gql`
  mutation removePurchaseOrderItem($id: Int!) {
    removePurchaseOrderItem(id: $id)
  }
`;

export const PO_ITEM_ROW_FROM_PURCHASE_ORDER_REMOVE = gql`
  mutation removePurchaseOrderItemRowFromPurchaseOrder($id: Int!) {
    removePurchaseOrderItemRowFromPurchaseOrder(id: $id)
  }
`;

export const PO_ITEM_NEW_ROW_CREATE = gql`
  mutation createPurchaseOrderItemRowForPurchaseOrder(
    $addPurchaseOrderItemInput: AddPurchaseOrderItemInput!
    $poItem: POOrderItemDocumentsInputDTO!
  ){
    createPurchaseOrderItemRowForPurchaseOrder(
      addPurchaseOrderItemInput: $addPurchaseOrderItemInput
      poItem: $poItem
    )
  }
`;

// -------------------------------------------------- APP USER MUTATIONS --------------------------------------------------------------------------------
export const CREATE_APP_USER_MUTATION = gql`
  mutation createAppUser($createAppUserInput: CreateAppUserInput!) {
    createAppUser(createAppUserInput: $createAppUserInput) {
      id
      name
    }
  }
`;
export const UPDATE_APP_USER_MUTATION = gql`
  mutation updateAppUser($updateAppUserInput: UpdateAppUserInput!) {
    updateAppUser(updateAppUserInput: $updateAppUserInput) {
      id
      name
      city
      lastname
      phoneNumber
      position
      email
      about
      avatar
    }
  }
`;
export const CREATE_SHIP_OWNER_USER_MUTATION = gql`
  mutation createShipOwnerUser(
    $createAppUserInput: CreateAppUserInput!
    $shipOwnerId: Int!
    $vesselIdsArray: [Int!]!
  ) {
    createShipOwnerUser(
      createAppUserInput: $createAppUserInput
      shipOwnerId: $shipOwnerId
      vesselIdsArray: $vesselIdsArray
    )
  }
`;
export const UPDATE_SHIP_OWNER_USER_MUTATION = gql`
  mutation updateShipOwnerUser(
    $appUserId: Int!
    $updateAppUserInput: UpdateAppUserInput!
    $shipOwnerId: Int!
    $vesselIdsArray: [Int!]!
  ) {
    updateShipOwnerUser(
      appUserId: $appUserId
      updateAppUserInput: $updateAppUserInput
      shipOwnerId: $shipOwnerId
      vesselIdsArray: $vesselIdsArray
    )
  }
`;

export const ADD_SHIP_OWNER_USER_MUTATION = gql`
  mutation addShipOwnerUser(
    $appUserId: Int!

    $shipOwnerId: Int!
    $vesselIdsArray: [Int!]!
    $includeAllVessels: Boolean!
  ) {
    addShipOwnerUser(
      appUserId: $appUserId

      shipOwnerId: $shipOwnerId
      vesselIdsArray: $vesselIdsArray
      includeAllVessels : $includeAllVessels
    )
  }
`;

export const REMOVE_APP_USER_MUTATION = gql`
  mutation removeAppUser($id: Int!) {
    removeAppUser(id: $id) {
      id
      name
    }
  }
`;

// -------------------------------------------------- SHIP OWNER MUTATIONS --------------------------------------------------------------------------------
export const CREATE_SHIP_OWNER_MUTATION = gql`
  mutation createShipOwner($createShipOwnerInput: CreateShipOwnerInput!) {
    createShipOwner(createShipOwnerInput: $createShipOwnerInput) {
      id
      name
    }
  }
`;
export const UPDATE_SHIP_OWNER_MUTATION = gql`
  mutation updateShipOwner($updateShipOwnerInput: UpdateShipOwnerInput!) {
    updateShipOwner(updateShipOwnerInput: $updateShipOwnerInput) {
      id
      name
    }
  }
`;
export const REMOVE_SHIP_OWNER_MUTATION = gql`
  mutation removeShipOwner($id: Int!) {
    removeShipOwner(id: $id)
  }
`;

export const UPLOAD_SUPPLIER_VESSELS_MUTATION = gql`
  mutation uploadSupplierVessels($file: Upload!) {
    uploadSupplierVessels(file: $file) {
      TotalRows
      Rows {
        name
        imoNumber
        vesselTypeId
        vesselTypeName
        email
        InternationalCallSign
        FlagState
        PortofRegistry
        Shipyard
        HullNo
        Capacity
        OwningCompany
        OwningCompanyAddress
        VesselClass
        IHMClass
        Fleet
        createAccount
        InitialIhmReportNumber
        InitialIhmReportCompanyName
        VesselOperator
      }
    }
  }
`;

export const INSERT_SHIPOWNER_VESSELS_MUTATION = gql`
  mutation insertShipOwnerVessels(
    $shipOwnerId: Int!
    $createVesselExcelInputArray: [CreateVesselExcelInput!]!
  ) {
    insertShipOwnerVessels(
      shipOwnerId: $shipOwnerId
      createVesselExcelInputArray: $createVesselExcelInputArray
    )
  }
`;

export const REMOVE_USER_SHIPOWNER_VESSELS_BY_VESSEL_ID_MUTATION = gql`
  mutation removeUserShipOwnerVesselByVesselId($vesselId: Int!) {
    removeUserShipOwnerVesselByVesselId(vesselId: $vesselId)
  }
`;
export const REMOVE_USER_SHIPOWNER_VESSELS_BY_APP_USER_ID_MUTATION = gql`
  mutation removeUserShipOwnerVesselByAppUserId(
    $appUserId: Int!
    $identityId: String!
  ) {
    removeUserShipOwnerVesselByAppUserId(
      appUserId: $appUserId
      identityId: $identityId
    )
  }
`;

export const REMOVE_USER_SHIPOWNER_AND_USER_SHIPOWNER_VESSELS_BY_APP_USER_ID_MUTATION = gql`
  mutation removeUserShipOwnerAndUserShipOwnerVesselByAppUserId(
    $souId: Int!
    $shipOwnerId: Int!
  ) {
    removeUserShipOwnerAndUserShipOwnerVesselByAppUserId(
      souId: $souId
      shipOwnerId: $shipOwnerId
    )
  }
`;

export const CREATE_USER_SHIPOWNER_VESSEL_MUTATION = gql`
  mutation createUserShipOwnerVessel(
    $createUserShipOwnerVesselInput: CreateUserShipOwnerVesselInput!
  ) {
    createUserShipOwnerVessel(
      createUserShipOwnerVesselInput: $createUserShipOwnerVesselInput
    ) {
      id
    }
  }
`;
export const CREATE_USER_SHIPOWNER_VESSELS_MUTATION = gql`
  mutation createUserShipOwnerVessels(
    $createUserShipOwnerVesselInputArray: [CreateUserShipOwnerVesselInput!]!
  ) {
    createUserShipOwnerVessels(
      createUserShipOwnerVesselInputArray: $createUserShipOwnerVesselInputArray
    )
  }
`;

export const CREATE_USER_SHIPOWNER_MUTATION = gql`
  mutation createUserShipOwner(
    $createUserShipOwnerInput: CreateUserShipOwnerInput!
  ) {
    createUserShipOwner(createUserShipOwnerInput: $createUserShipOwnerInput) {
      id
      includeAllVessels
    }
  }
`;

// -------------------------------------------------- SUPPLIER MUTATIONS --------------------------------------------------------------------------------
export const UPDATE_SUPPLIER_NOTIFICATION_STATE = gql`
  mutation changeSendNotificationsState($supplierId: Int!, $state: Boolean!){
    changeSendNotificationsState(supplierId: $supplierId, state: $state)
  }
`;

export const UPDATE_SUPPLIER_ENABLE_SUPPLIER_PORTAL_STATE = gql`
  mutation changeEnableSupplierPortalState($supplierId: Int!, $state: Boolean!){
    changeEnableSupplierPortalState(supplierId: $supplierId, state: $state)
  }
`;

export const UPDATE_SUPPLIER_ENABLE_MANAGE_SPAREPARTS_PORTAL_STATE = gql`
  mutation changeEnableSparePartsManagementPortalState($supplierId: Int!, $state: Boolean!){
    changeEnableSparePartsManagementPortalState(supplierId: $supplierId, state: $state)
  }
`;

export const UPDATE_SUPPLIER_ENABLE_GENERAL_MDS_STATE = gql`
  mutation changeEnableGeneralMdsState($supplierId: Int!, $state: Boolean!){
    changeEnableGeneralMdsState(supplierId: $supplierId, state: $state)
  }
`;

export const CREATE_SUPPLIER_GENERAL_DATA = gql`
  mutation addNewProductOfSupplier($createSupplierGeneralDataInput: CreateSupplierGeneralDataInput!){
    addNewProductOfSupplier(createSupplierGeneralDataInput: $createSupplierGeneralDataInput)
  }
`;

export const DELETE_DOCUMENT_OF_PRODUCT = gql`
  mutation deleteGeneralDocumentOfProduct($id: Int!, $supplierId: Int!){
    deleteGeneralDocumentOfProduct(id: $id, supplierId: $supplierId)
  }
`;

export const DELETE_PRODUCT_AND_DOCUMENTS = gql`
  mutation deleteProductAndDocuments($id: Int!){
    deleteProductAndDocuments(id: $id)
  }
`;

export const UPDATE_ORDER_OF_PRODUCT = gql`
  mutation updateOrderOfProduct($ids: [Int!]!){
    updateOrderOfProduct(ids: $ids)
  }
`;

export const CREATE_SUPPLIER_MUTATION = gql`
  mutation createSupplier($createSupplierInput: CreateSupplierInput!) {
    createSupplier(createSupplierInput: $createSupplierInput) {
      id
      name
    }
  }
`;
export const UPDATE_SUPPLIER_MUTATION = gql`
  mutation updateSupplier($updateSupplierInput: UpdateSupplierInput!) {
    updateSupplier(updateSupplierInput: $updateSupplierInput) {
      id
      name
    }
  }
`;
export const CREATE_USER_SUPPLIER_MUTATION = gql`
  mutation createUserSupplier(
    $createUserSupplierInput: CreateUserSupplierInput!
  ) {
    createUserSupplier(createUserSupplierInput: $createUserSupplierInput) {
      id
      appUserId
      supplierId
    }
  }
`;
export const DELETE_SUPPLIER_USER_MUTATION = gql`
  mutation deleteSupplierUser($appUserId: Int!, $identityId: String!) {
    deleteSupplierUser(appUserId: $appUserId, identityId: $identityId)
  }
`;

export const REMOVE_USER_SUPPLIER_MUTATION = gql`
  mutation removeUserSupplierByUserId(
    $supplierId: Int!
    $supplierUserId: Int!
  ) {
    removeUserSupplierByUserId(
      supplierId: $supplierId
      supplierUserId: $supplierUserId
    )
  }
`;

export const SET_DEFAULT_USER_SUPPLIER_MUTATION = gql`
  mutation setUserSupplierDefault($appUserId: Int!, $supplierId: Int!) {
    setUserSupplierDefault(appUserId: $appUserId, supplierId: $supplierId)
  }
`;
// -------------------------------------------------- SUPPLIER IMAGES MUTATIONS --------------------------------------------------------------------------------
export const UPLOAD_SUPPLIER_IMAGE_MUTATION = gql`
  mutation uploadSupplierImage($file: Upload!, $supplierId: Int!) {
    uploadSupplierImage(file: $file, supplierId: $supplierId)
  }
`;

// -------------------------------------------------- VESSEL MUTATIONS --------------------------------------------------------------------------------
export const CREATE_VESSEL_MUTATION = gql`
  mutation createVessel($createVesselInput: CreateVesselInput!) {
    createVessel(createVesselInput: $createVesselInput) {
      id
      name
      ccIhmExport
      doNotShareIhm
    }
  }
`;

export const TRANSFER_VESSEL_FROM_SHIPOWNER = gql`
  mutation transferVesselFromShipOwner(
    $vesselImo: String!,
    $shipOwnerId: Int!
  ){
    transferVesselFromShipOwner(
      vesselImo: $vesselImo,
      shipOwnerId: $shipOwnerId
    )
  }
`;

export const UPDATE_VESSEL_MUTATION = gql`
  mutation updateVessel($updateVesselInput: UpdateVesselInput!) {
    updateVessel(updateVesselInput: $updateVesselInput) {
      id
      name
      ccIhmExport
      doNotShareIhm
    }
  }
`;

export const CREATE_VESSEL_PERSON_RESPONSIBLE_MUTATION = gql`
  mutation createVesselPersonResponsible(
    $createVesselPersonResponsibleInput: CreateVesselPersonResponsibleInput!
  ) {
    createVesselPersonResponsible(
      createVesselPersonResponsibleInput: $createVesselPersonResponsibleInput
    ) {
      id
      firstName
      lastName
    }
  }
`;
export const UPDATE_VESSEL_PERSON_RESPONSIBLE_MUTATION = gql`
  mutation updateVesselPersonResponsible(
    $updateVesselPersonResponsibleInput: UpdateVesselPersonResponsibleInput!
  ) {
    updateVesselPersonResponsible(
      updateVesselPersonResponsibleInput: $updateVesselPersonResponsibleInput
    ) {
      id
      firstName
      lastName
    }
  }
`;
// -------------------------------------------------- VESSEL IMAGES MUTATIONS --------------------------------------------------------------------------------
export const UPLOAD_VESSEL_IMAGE_MUTATION = gql`
  mutation uploadVesselImage($file: Upload!, $vesselId: Int!) {
    uploadVesselImage(file: $file, vesselId: $vesselId)
  }
`;

export const REPLACE_VESSEL_IMAGE_MUTATION = gql`
  mutation replaceVesselImage($file: Upload!, $id: Int!, $vesselId: Int!) {
    replaceVesselImage(file: $file, id: $id, vesselId: $vesselId)
  }
`;

export const DELETE_VESSEL_IMAGE_MUTATION = gql`
  mutation removeVesselImage($id: Int!) {
    removeVesselImage(id: $id)
  }
`;

// -------------------------------------------------- VESSEL DRAWING MUTATIONS --------------------------------------------------------------------------------
export const UPLOAD_VESSEL_DRAWING_VIEW = gql`
  mutation uploadVesselDrawingView(
    $file: Upload!
    $vesselId: Int!
    $fileName: String!
    $mimeType: String!
  ) {
    uploadVesselDrawingView(
      file: $file
      vesselId: $vesselId
      fileName: $fileName
      mimeType: $mimeType
    )
  }
`;

export const REMOVE_VESSEL_DRAWING_VIEW = gql`
  mutation removeVesselDrawingView($vesselDrawingId: Int!) {
    removeVesselDrawingView(vesselDrawingId: $vesselDrawingId)
  }
`;

// -------------------------------------------------- ΙΗΜ MUTATIONS --------------------------------------------------------------------------------
export const UPLOAD_IHM_POSITION_IMAGE_MUTATION = gql`
  mutation uploadIhmPositionImage(
    $file: Upload!
    $vesselId: Int!
    $ihmItemId: Int!
    $ihmItemPositionId: Int!
  ) {
    uploadIhmPositionImage(
      file: $file
      vesselId: $vesselId
      ihmItemId: $ihmItemId
      ihmItemPositionId: $ihmItemPositionId
    )
  }
`;

export const UPLOAD_IHM_ATTACHMENT_MUTATION = gql`
  mutation uploadVesselIhmAttachment(
    $file: Upload!
    $vesselId: Int!
    $ihmItemId: Int!
  ) {
    uploadVesselIhmAttachment(
      file: $file
      vesselId: $vesselId
      ihmItemId: $ihmItemId
    )
  }
`;

export const UPLOAD_VESSEL_ATTACHMENT_MUTATION = gql`
  mutation uploadVesselAttachment(
    $file: Upload!
    $vesselId: Int!
    $vesselIdf: Int!
  ) {
    uploadVesselAttachment(
      file: $file
      vesselId: $vesselId
      vesselIdf: $vesselIdf
    )
  }
`;

export const IHM_ATTACHMENT_SET_INCLUDE_TO_EXPORT_MUTATION = gql`
  mutation vesselIhmAttachmentSetIncludeToExport($vesselIhmAttachmentId: Int!) {
    vesselIhmAttachmentSetIncludeToExport(
      vesselIhmAttachmentId: $vesselIhmAttachmentId
    ) {
      includeToExport
    }
  }
`;

export const VESSEL_ATTACHMENT_SET_INCLUDE_TO_EXPORT_MUTATION = gql`
  mutation vesselAttachmentSetIncludeToExport($vesselAttachmentId: Int!) {
    vesselAttachmentSetIncludeToExport(
      vesselAttachmentId: $vesselAttachmentId
    ) {
      id
      includeToExport
    }
  }
`;

export const VESSEL_ATTACHMENT_VALUES = gql`
 mutation vesselAttach($vesselId: Int!){
    id
    name
    path

  }
`;

export const REMOVE_IHM_ATTACHMENT_MUTATION = gql`
  mutation removeVesselIhmAttachment(
    $vesselId: Int!
    $ihmItemId: Int!
    $attachmentId: Int!
  ) {
    removeVesselIhmAttachment(
      vesselId: $vesselId
      ihmItemId: $ihmItemId
      attachmentId: $attachmentId
    )
  }
`;

export const REMOVE_VESSEL_ATTACHMENT_MUTATION = gql`
  mutation removeVesselAttachment(
    $vesselId: Int!
    $attachmentId: Int!
  ) {
    removeVesselAttachment(
      vesselId: $vesselId
      attachmentId: $attachmentId
    )
  }
`;

export const UPLOAD_IHMS_MUTATION = gql`
  mutation uploadIhmItems($file: Upload!) {
    uploadIhmItems(file: $file) {
      TotalRows
      Rows {
        installationNumber
        ihmId
        materialName
        materialName2
        materialName3
        materialCategory
        materialCategory2
        materialCategory3
        materialThresholdValue
        materialThresholdValue2
        materialThresholdValue3
        materialMass
        materialMass2
        materialMass3
        materialUnit
        materialUnit2
        materialUnit3
        materialComments
        materialComments2
        materialComments3
        referenceNumber
        materialPresentAboveThresholdValue
        equipmentManufacturer
        equipmentDescription
        usedParts
        productCode
        productName
        quantity
        ihmUnit
        containsPCHM
        inventoryPart
        inventorySubPart
        dateAdded
        dateApproved
        approvedBy
        deleted
        status
        replacedByIhmItemId
        applicationOfPaint
        nameOfPaint
        nameOfEquipmentAndMachinery
        nameOfStructuralElement
        partsWhereUsed
        location
        positionDescription
        vesselDrawingViewPath
        isApproved
        title
      }
    }
  }
`;

export const INSERT_IHM_ITEMS_MUTATION = gql`
  mutation insertIhmItems(
    $vesselId: Int!
    $createIhmMaterialRowInputArray: [CreateIhmMaterialRowInput!]!
  ) {
    insertIhmItems(
      vesselId: $vesselId
      createIhmMaterialRowInputArray: $createIhmMaterialRowInputArray
    ) {
      persistedRowIndexArray
      failedRowIndexArray
    }
  }
`;

export const CREATE_IHM_MUTATION = gql`
  mutation createIhmItemWithVPOIandHistory(
    $createIhmItemInput: CreateIhmItemInput!
    $vesselId: Int!
  ) {
    createIhmItemWithVPOIandHistory(
      createIhmItemInput: $createIhmItemInput
      vesselId: $vesselId
    ) {
      id
    }
  }
`;

export const CREATE_SERIAL_NUMBER = gql`
  mutation createSerialNumber(
    $createSpSerialNumberInput: CreateSpSerialNumberInput!
  ){
    createSerialNumber(
      createSpSerialNumberInput: $createSpSerialNumberInput
    )
  }
`;

export const DELETE_SP_SERIAL_NUMBER = gql`
  mutation deleteSerialNumber(
    $id: Int!
  ){
    deleteSerialNumber(
      id: $id
    )
  }
`;

export const UPDATE_SP_SERIAL_NUMBER = gql`
  mutation updateSpSerialNumber(
    $id: Int!
    $systemId: Int!
    $isEx: String!
    $troManufacturer: String!
    $filterManufacturer: String!
    $serialNumber: String!
  ){
    updateSpSerialNumber(
        id: $id
        systemId:  $systemId
        isEx:  $isEx
        troManufacturer:  $troManufacturer
        filterManufacturer:  $filterManufacturer
        serialNumber:  $serialNumber
    )
  }
`;

export const UPDATE_IHM_MUTATION = gql`
  mutation updateIhmItem($updateIhmItemInput: UpdateIhmItemInput!) {
    updateIhmItem(updateIhmItemInput: $updateIhmItemInput) {
      id
    }
  }
`;

export const REMOVE_IHM_ITEM_AND_CASCADE_MUTATION = gql`
  mutation removeIhmItemAndAssociatedEntities($ihmItemId: Int!) {
    removeIhmItemAndAssociatedEntities(ihmItemId: $ihmItemId)
  }
`;

export const REMOVE_IHM_ITEMS_AND_CASCADE_MUTATION = gql`
  mutation removeIhmItemsAndAssociatedEntities($ihmItemIds: [Int!]!) {
    removeIhmItemsAndAssociatedEntities(ihmItemIds: $ihmItemIds)
  }
`;

//--------------------------------------------------- IHM VERSIONING -------------------------------------------------------------------------------
export const UPDATE_IHM_VERSIONING = gql`
  mutation updateIhmVersioning(
    $ihmVersioningComments: [CreateIhmVersioningCommentsInput!]!
  ){
    updateIhmVersioning(ihmVersioningComments: $ihmVersioningComments)
  }
`;

export const CREATE_NEW_VERSION_FOR_VESSEL_DATA = gql`
  mutation createNewVersionForVesselData($selectedVesselId: Int!){
    createNewVersionForVesselData(selectedVesselId: $selectedVesselId)
  }
`;
// -------------------------------------------------- ΙΗΜ MATERIAL  --------------------------------------------------------------------------------
export const CREATE_IHM_MATERIAL = gql`
  mutation createIhmItemMaterial(
    $createIhmItemMaterialInput: CreateIhmItemMaterialInput!
  ) {
    createIhmItemMaterial(
      createIhmItemMaterialInput: $createIhmItemMaterialInput
    ) {
      id
      name
      category
      thresholdValue
      presentAboveThresholdValue
      mass
      unit
      comments
      ihmItemId
    }
  }
`;

export const UPDATE_IHM_MATERIAL = gql`
  mutation updateIhmItemMaterial(
    $updateIhmItemMaterialInput: UpdateIhmItemMaterialInput!
  ) {
    updateIhmItemMaterial(
      updateIhmItemMaterialInput: $updateIhmItemMaterialInput
    ) {
      id
      name
      category
      thresholdValue
      presentAboveThresholdValue
      mass
      unit
      comments
      ihmItemId
    }
  }
`;

export const REMOVE_IHM_MATERIAL_MUTATION = gql`
  mutation removeIhmItemMaterial($id: Int!) {
    removeIhmItemMaterial(id: $id)
  }
`;

// -------------------------------------------------- ΙΗΜ POSITION  --------------------------------------------------------------------------------
export const UPDATE_IHM_POSITION_MAP_IMAGE = gql`
  mutation updateIhmPositionMapImage(
    $id: Int!
    $archive: Upload!
    ) {
    updateIhmPositionMapImage(
      id: $id
      archive: $archive
      )
  }
`;

export const CREATE_IHM_POSITION = gql`
  mutation createIhmPosition(
    $createIhmPositionInput: CreateIhmPositionInput!
    $archive: Upload!
    ) {
    createIhmPosition(
      createIhmPositionInput: $createIhmPositionInput
      archive: $archive
      ) {
      id
      x
      y
      ihmItemId
    }
  }
`;
export const REPLACE_IHM_POSITION = gql`
  mutation replaceInstallation(
    $id: Int!
    $createIhmPositionInput: CreateIhmPositionInput!
  ) {
    replaceInstallation(
      id: $id
      createIhmPositionInput: $createIhmPositionInput
    )
  }
`;

export const REMOVE_IHM_INSTALLATION = gql`
  mutation removeInstallation($id: Int!) {
    removeInstallation(id: $id)
  }
`;

export const UPDATE_IHM_POSITION = gql`
  mutation updateIhmPosition($updateIhmPositionInput: UpdateIhmPositionInput!) {
    updateIhmPosition(updateIhmPositionInput: $updateIhmPositionInput) {
      id
      x
      y
      ihmItemId
    }
  }
`;

export const APPROVE_INSTALLATION_MUTATION = gql`
  mutation approveInstallation(
    $updateIhmPositionInput: UpdateIhmPositionInput!
  ) {
    approveInstallation(updateIhmPositionInput: $updateIhmPositionInput)
  }
`;

export const APPROVE_INSTALLATION_REPLACE_MUTATION = gql`
  mutation approveInstallationReplace(
    $ihmPositionIdToBeReplaced: Int!
    $ihmPositionIdToReplace: Int!
  ) {
    approveInstallationReplace(
      ihmPositionIdToBeReplaced: $ihmPositionIdToBeReplaced
      ihmPositionIdToReplace: $ihmPositionIdToReplace
    )
  }
`;

export const REJECT_REPLACEMENT_MUTATION = gql`
  mutation rejectReplacement($replacedByIhmPositionId: Int!) {
    rejectReplacement(replacedByIhmPositionId: $replacedByIhmPositionId)
  }
`;

export const REJECT_INSTALLATION_MUTATION = gql`
  mutation rejectInstallation($ihmPositionIdToReject: Int!) {
    rejectInstallation(ihmPositionIdToReject: $ihmPositionIdToReject)
  }
`;

export const REJECT_REMOVAL_MUTATION = gql`
  mutation rejectPositionRemoval($ihmPositionIdToReject: Int!) {
    rejectPositionRemoval(ihmPositionIdToReject: $ihmPositionIdToReject)
  }
`;

export const PLACE_IHM_POSITION_ON_MAP = gql`
  mutation placeInstalledItemToMap(
    $updateIhmPositionInput: UpdateIhmPositionInput!
    $archive: Upload!
  ) {
    placeInstalledItemToMap(
      updateIhmPositionInput: $updateIhmPositionInput
      archive: $archive
      ) {
      id
      x
      y
      ihmItemId
    }
  }
`;

export const DELETE_IHM_POSITION_BY_LATLNG = gql`
  mutation removeIhmPositionByLatLng(
    $latLng: PositionLatLng!
    $vesselDrawingPath: String!
  ) {
    removeIhmPositionByLatLng(
      latLng: $latLng
      vesselDrawingPath: $vesselDrawingPath
    )
  }
`;

export const DELETE_IHM_POSITION_AND_IMAGE = gql`
  mutation removePositionImageAndPositionByPositionId($positionId: Int!) {
    removePositionImageAndPositionByPositionId(positionId: $positionId)
  }
`;

export const CREATE_VESSEL_PURCHASE_ORDER_ITEM_IHM_MUTATION = gql`
  mutation createVesselPurchaseOrderItemIhm(
    $createVesselPurchaseOrderItemIhmInput: CreateVesselPurchaseOrderItemIhmInput!
  ) {
    createVesselPurchaseOrderItemIhm(
      createVesselPurchaseOrderItemIhmInput: $createVesselPurchaseOrderItemIhmInput
    ) {
      id
    }
  }
`;

export const UPDATE_VESSEL_PURCHASE_ORDER_ITEM_IHM_MUTATION = gql`
  mutation updateVesselPurchaseOrderItemIhm(
    $updateVesselPurchaseOrderItemIhmInput: UpdateVesselPurchaseOrderItemIhmInput!
  ) {
    updateVesselPurchaseOrderItemIhm(
      updateVesselPurchaseOrderItemIhmInput: $updateVesselPurchaseOrderItemIhmInput
    ) {
      id
    }
  }
`;

// --------------------------------- PURCHASE ORDER ITEM CHAT MUTATIONS -------------------------------------
export const CREATE_PURCHASE_ORDER_ITEM_CHAT = gql`
  mutation createPurchaseOrderItemChat(
    $createPurchaseOrderItemChat: CreatePurchaseOrderItemChatDto!
  ) {
    createPurchaseOrderItemChat(
      CreatePurchaseOrderItemChatDto: $createPurchaseOrderItemChat
    ) {
      id
    }
  }
`;

export const CREATE_PURCHASE_ORDER_CHAT = gql`
  mutation createPurchaseOrderChat(
    $createPurchaseOrderChat: CreatePoRequestSupplierChatInput!
  ) {
    createPurchaseOrderChat(
      CreatePurchaseOrderItemChatDto: $createPurchaseOrderChat
    ) {
      id
    }
  }
`;
// --------------------------------- PURCHASE ORDER ITEM DOCUMENTS MUTATIONS -------------------------------------
export const DELETE_DOCUMENT_AND_RELATIONS_BY_PO_ITEM_ID_MUTATION = gql`
  mutation deleteDocumentAndRelationsByPoItemId($poItemId: Int!) {
    deleteDocumentAndRelationsByPoItemId(poItemId: $poItemId)
  }
`;

export const UPDATE_PO_ITEM_UPLOADED_DOCUMENT_MUTATION = gql`
  mutation updatePoItemUploadedDocument(
    $updatePoItemUploadedDocumentInput: UpdatePoItemUploadedDocumentInput!
  ) {
    updatePoItemUploadedDocument(
      updatePoItemUploadedDocumentInput: $updatePoItemUploadedDocumentInput
    ) {
      id
      name
      mimeType
      storagePath
      containsSdoc
      containsMd
    }
  }
`;

export const UPLOAD_PO_ITEMS_DOCUMENTS_MUTATION = gql`
  mutation uploadPurchaseOrderItemDocuments(
    $poItemIds: [Int!]!
    $containsHazMat: Boolean!
    $fileList: [Upload!]!
    $purchaseOrderNumber: String!
    $supplierRequestId: String!
    $vesselImo: String!
    $poDate: String!
    $supplierReferenceNumber: String!
  ) {
    uploadPurchaseOrderItemDocuments(
      poItemIds: $poItemIds
      containsHazMat: $containsHazMat
      fileList: $fileList
      purchaseOrderNumber: $purchaseOrderNumber
      supplierRequestId: $supplierRequestId
      vesselImo: $vesselImo
      poDate: $poDate
      supplierReferenceNumber: $supplierReferenceNumber
    )
  }
`;

export const UPLOAD_GENERAL_DOCUMENTS_OF_PRODUCT = gql`
  mutation uploadGeneralDocumentsOfProduct(
    $fileList: [Upload!]!
    $supplierGeneralDataId: Int!
    $selectedSupplierId: Int!
  ){
    uploadGeneralDocumentsOfProduct(
      fileList: $fileList
      supplierGeneralDataId: $supplierGeneralDataId
      selectedSupplierId: $selectedSupplierId
    )
  }
`;

export const UPDATE_SUPPLIER_ENABLE_GENERAL_MDS_OF_PRODUCT_STATE = gql`
  mutation updateSupplierEnableGeneralMdsOfProductState($id: Int!, $state: Boolean!){
    updateSupplierEnableGeneralMdsOfProductState(id: $id, state: $state)
  }
`;

export const MASSIVE_UPLOAD_PURCHASE_ORDE_ITEM_DOCUMENTS_GREEN = gql`
  mutation massiveUploadPurchaseOrderItemDocumentsGreen(
    $purchaseOrdersToBeSearched: [PurchaseOrdersToSearch!]!
    $containsHazMat: Boolean!
    $fileList: [Upload!]!

  ) {
    massiveUploadPurchaseOrderItemDocumentsGreen(
      purchaseOrdersToBeSearched: $purchaseOrdersToBeSearched
      containsHazMat: $containsHazMat
      fileList: $fileList

    )
  }
`;

export const UPLOAD_PO_ITEM_DOCUMENT = gql`
  mutation uploadPoDocument($file: Upload!, $poItemId: Int!) {
    uploadPoDocument(file: $file, poItemId: $poItemId)
  }
`;

export const REMOVE_PO_ITEM_DOCUMENT = gql`
  mutation removePoItemDocument($id: Int!) {
    removePoItemDocument(id: $id)
  }
`;

// ------------------------------------------ PURCHASE ORDER REQUESTS --------------------------------------------
export const GENERATE_PURCHASE_ORDER_ITEM_FORMS_MUTATION = gql`
  mutation generateForms(
    $poItemId: Int!
    $createPurchaseOrderItemMdMaterialInput: [CreatePurchaseOrderItemMdMaterialInput!]!
    $mdAmount: Int!
    $mdUnit: String!
    $mdRemark3: String!
    $mdAmountDelivery: Int!
    $mdUnitDelivery: String!
  ) {
    generateForms(
      poItemId: $poItemId
      createPurchaseOrderItemMdMaterialInput: $createPurchaseOrderItemMdMaterialInput
      mdAmount: $mdAmount
      mdUnit: $mdUnit
      mdRemark3: $mdRemark3
      mdAmountDelivery: $mdAmountDelivery
      mdUnitDelivery: $mdUnitDelivery
    ) {
      id
      purchaseOrderId
      vesselId
      supplierId
      equipmentManufacturer
      equipmentDescription
      productName
      productCode
      quantity
      unit
      containsPCHM
      containsCHM
      inScope
      EquipmentId
      ProductDescription
      OrderStatus
      MdSDoCStatus
      ExternalCategory
      SupplierName
      PoDate
    }
  }
`;

export const SEND_NEW_ACCOUNT_INVITATION_FOR_SUPPLIER = gql`
  mutation sendNewAccountInvitationForSupplier(
    $poId: Int!
    $supplierRequestId: String!
  ){
    sendNewAccountInvitationForSupplier(
      poId: $poId
      supplierRequestId: $supplierRequestId
    )
  }`

export const SEND_SUPPLIER_PO_INVITATION = gql`
  mutation sendSupplierPoInvitation(
    $poId: Int!
    $supplierRequestId: String!
    $vesselImo: String!
    $poDateRaw: String!
  ){
    sendSupplierPoInvitation(
      poId: $poId
      supplierRequestId: $supplierRequestId
      vesselImo: $vesselImo
      poDateRaw: $poDateRaw
    )
  }`

export const SEND_PO_GENERATE_DOCUMENTS = gql`
  mutation generatePdfDocuments(
    $poId: Int!
    $supplierRequestId: String!
  ){
    generatePdfDocuments(
      poId: $poId
      supplierRequestId: $supplierRequestId
    )
  }`


export const DELETE_PO_ITEM_AND_DOCS_MUTATION = gql`
  mutation deletePoItemsAndDocs(
    $poId: Int!
    $supplierRequestId: String!
    $shipOwnerId: Int!
    $purchaseOrderNumber: String!
    $poDate: String!
    $supplierReferenceNumber: String!
    $poDateRaw: String!
    $vesselImo: String!
  ) {
    deletePoItemsAndDocs(
      poId: $poId
      supplierRequestId: $supplierRequestId
      shipOwnerId: $shipOwnerId
      purchaseOrderNumber: $purchaseOrderNumber
      poDate: $poDate
      supplierReferenceNumber: $supplierReferenceNumber
      poDateRaw: $poDateRaw
      vesselImo: $vesselImo
    )
  }
`;

export const REPLACE_PURCHASE_ORDER_ITEM_MATERIALS = gql`
  mutation replaceMaterials(
    $purchaseOrderId: Int!
    $updatePurchaseOrderItemMdMaterialInput: [CreatePurchaseOrderItemMdMaterialInput!]!
    $amount: Int!
    $unit: String!
  ) {
    replaceMaterials(
      purchaseOrderId: $purchaseOrderId
      updatePurchaseOrderItemMdMaterialInput: $updatePurchaseOrderItemMdMaterialInput
      amount: $amount
      unit: $unit
    ) {
      id
      purchaseOrderItemSupplierDocumentId
      idNo
      remark1
      remark2
      remark3
      companyName
      divisionName
      address
      contactPerson
      telephoneNumber
      faxNumber
      emailAddress
    }
  }
`;

export const UPDATE_PURCHASE_ORDER_ITEM_MD = gql`
  mutation updatePurchaseOrderItemMd(
    $updatePurchaseOrderItemMdInput: UpdatePurchaseOrderItemMdInput!
  ) {
    updatePurchaseOrderItemMd(
      updatePurchaseOrderItemMdInput: $updatePurchaseOrderItemMdInput
    ) {
      id
    }
  }
`;

export const UPDATE_PURCHASE_ORDER_SUPPLIER_DOCUMENT_SDOC = gql`
  mutation updatePurchaseOrderSupplierDocumentSdoc(
    $updatePurchaseOrderSupplierDocumentSdocInput: UpdatePurchaseOrderSupplierDocumentSdocInput!
  ) {
    updatePurchaseOrderSupplierDocumentSdoc(
      updatePurchaseOrderSupplierDocumentSdocInput: $updatePurchaseOrderSupplierDocumentSdocInput
    ) {
      id
    }
  }
`;

export const MOVE_TO_STATE = gql`
  mutation MoveToState(
    $poItemId: Int!
    $supplierRequestId: String!
    $state: Int!
  ) {
    MoveToState(
      poItemId: $poItemId
      supplierRequestId: $supplierRequestId
      state: $state
    )
  }
`;

export const MOVE_PO_REQUEST_TO_ANOTHER_SUPPLIER = gql`
  mutation movePoRequestToAnotherSupplier(
    $supplierIdFrom: Int!,
    $purchaseOrderNumber: String!,
    $supplierRequestId: String!,
    $supplierIdTo: Int!,
  ) {
    movePoRequestToAnotherSupplier(
      supplierIdFrom: $supplierIdFrom,
      purchaseOrderNumber: $purchaseOrderNumber,
      supplierRequestId: $supplierRequestId,
      supplierIdTo: $supplierIdTo,
    )
  }
`;

export const MOVE_SUPPLIER_USER = gql`
  mutation transferUserSupplier(
    $supplierFromId: Int!,
    $supplierToId: Int!,
    $selectedUserId: Int!,
  ) {
    transferUserSupplier(
      supplierFromId: $supplierFromId,
      supplierToId: $supplierToId,
      selectedUserId: $selectedUserId,
    )
  }
`;

export const RESUBMIT_TO_SUPPLIER_MUTATION = gql`
  mutation ResubmitToSupplier(
    $reason: String!
    $poItemIds: [Int!]!
    $comments: String!
    $supplierRequestId: String!
    $state: Int!
    $purchaseOrderNumber: String!
    $poDate: String!
    $supplierReferenceNumber: String!
    $vesselImo: String!
    $resetPurchaseOrderDocuments: Boolean!
    $poRequestSupplierIdsForReset: [Int!]!
  ) {
    ResubmitToSupplier(
      reason: $reason
      poItemIds: $poItemIds
      comments: $comments
      supplierRequestId: $supplierRequestId
      state: $state
      purchaseOrderNumber: $purchaseOrderNumber
      poDate: $poDate
      supplierReferenceNumber: $supplierReferenceNumber
      vesselImo: $vesselImo
      resetPurchaseOrderDocuments: $resetPurchaseOrderDocuments
      poRequestSupplierIdsForReset: $poRequestSupplierIdsForReset
    )
  }
`;

export const UPDATE_PO_REQUEST_SUPPLIER_CONTAINS_CHM = gql`
  mutation updatePoRequestSupplierContainsChm(
    $id: Int!
    $containsCHM: Boolean!
  ) {
    updatePoRequestSupplierContainsChm(id: $id, containsCHM: $containsCHM) {
      id
    }
  }
`;

export const UPDATE_PO_REQUEST_SUPPLIER_CONTAINS_CHM_ALL_MATERIALS = gql`
  mutation updatePoRequestSupplierContainsChmAllMaterials(
    $id: Int!
    $containsCHM: Boolean!
  ) {
    updatePoRequestSupplierContainsChmAllMaterials(id: $id, containsCHM: $containsCHM) {
      id
    }
  }
`;

export const SET_PORS_IN_SCOPE_MUTATION = gql`
  mutation setPorsInScope($poRequestSupplierIdsArray: [Int!]!) {
    setPorsInScope(poRequestSupplierIdsArray: $poRequestSupplierIdsArray)
  }
`;

// -------------------------------------------------- EXPORT PURCHASE ORDER MUTATIONS ----------------------------------------------------------------------------
export const DOWNLOAD_EXCEL_OF_PURCHASE_ORDERS_MUTATION = gql`
  mutation downloadExcelOfPurchaseOrders(
    $states: [Int!]!
    $supplierRequestId: String!
    $limit: Int!
    $page: Int!
    $selectedVesselId: Int!
    $filtersArray: [InScopeValidationFilter!]!
    $sortingOrder: InScopeValidationOrder!
    $inScope: Int!
    $containsHazMat: Int!
    $documentType: [Int!]!
    $orderStatuses: [Int!]!
    $poRequestStatus: Int!
    $shipOwnerId: Int
  ){
    downloadExcelOfPurchaseOrders(
      states: $states
      supplierRequestId: $supplierRequestId
      limit: $limit
      page: $page
      selectedVesselId: $selectedVesselId
      filtersArray: $filtersArray
      sortingOrder: $sortingOrder
      inScope: $inScope
      containsHazMat: $containsHazMat
      documentType: $documentType
      orderStatuses: $orderStatuses
      poRequestStatus: $poRequestStatus
      shipOwnerId: $shipOwnerId
    )
  }
`;
// -------------------------------------------------- IMPORT PURCHASE ORDER MUTATIONS ----------------------------------------------------------------------------

export const REMOVE_WIZARD_MUTATION = gql`
  mutation removeWizard($wizardId: Int!) {
    removeWizard(wizardId: $wizardId)
  }
`;

export const MAP_EXCEL_HEADERS_TO_DB_COLUMN = gql`
  mutation mapExcelHeadersToDbColumnJob(
    $shipOwnerId: Int!
    $poMappedExcelColumnInput: [PoMappedExcelColumnInput!]!
    $file: Upload!
  ) {
    mapExcelHeadersToDbColumnJob(
      shipOwnerId: $shipOwnerId
      poMappedExcelColumnInput: $poMappedExcelColumnInput
      file: $file
    ) {
      WizardId
      Message
      Rows {
        ExcelRowId
        ExcelColumnName
        ExcelColumnValue
        Comment
        IsValid
        Suggestions {
          PoRequestSupplierId
          SuggestionValue
        }
      }
      ExcludedRows {
        id
        poNumber
        vesselName
        vesselImo
        equipmentManufacturer
        equipmentDescription
        productCode
        productName
        quantity
        containsPCHM
        containsCHM
        inScope
        poDate
        equipmentId
        productDescription
        general
        orderStatus
        mdSDoCStatus
        formType
        externalCategory
        supplierRequestName
        supplierRequestEmailAddress
        doNotExecuteWorkflow
        matching
      }
      IsValid
    }
  }
`;

export const IMPORT_VALIDATE_VESSEL_MUTATION = gql`
  mutation importValidateVessel($wizardId: Int!) {
    importValidateVessel(wizardId: $wizardId) {
      Message
      Rows {
        ExcelColumnName
        ExcelColumnValue
        Comment
        IsValid
        Suggestions {
          PoRequestSupplierId
          SuggestionValue
        }
      }
      ExcludedRows {
        id
        poNumber
        vesselName
        vesselImo
        equipmentManufacturer
        equipmentDescription
        productCode
        productName
        quantity
        containsPCHM
        containsCHM
        inScope
        poDate
        equipmentId
        productDescription
        general
        orderStatus
        mdSDoCStatus
        formType
        externalCategory
        supplierRequestName
        supplierRequestEmailAddress
        doNotExecuteWorkflow
        matching
      }
      IsValid
    }
  }
`;

export const IMPORT_VALIDATE_SUPPLIERS_MUTATION = gql`
  mutation importValidateSuppliers($wizardId: Int!) {
    importValidateSuppliers(wizardId: $wizardId) {
      Message
      Rows {
        ExcelColumnName
        ExcelColumnValue
        Comment
        IsValid
        Suggestions {
          PoRequestSupplierId
          SuggestionValue
        }
      }
      ExcludedRows {
        id
        poNumber
        vesselName
        vesselImo
        equipmentManufacturer
        equipmentDescription
        productCode
        productName
        quantity
        containsPCHM
        containsCHM
        inScope
        poDate
        equipmentId
        productDescription
        general
        orderStatus
        mdSDoCStatus
        formType
        externalCategory
        supplierRequestName
        supplierRequestEmailAddress
        doNotExecuteWorkflow
        matching
      }
      IsValid
    }
  }
`;

export const IMPORT_VALIDATE_PO_MUTATION = gql`
  mutation importValidatePo($wizardId: Int!) {
    importValidatePo(wizardId: $wizardId) {
      Message
      Rows {
        ExcelColumnName
        ExcelColumnValue
        Comment
        IsValid
        Suggestions {
          PoRequestSupplierId
          SuggestionValue
        }
      }
      ExcludedRows {
        id
        poNumber
        vesselName
        vesselImo
        poDate
        supplierRequestEmailAddress
        orderStatus
        supplierId
        supplierReferenceNumber
        poRequestSupplierId
        appUserEmail
      }
      IsValid
    }
  }
`;

export const IMPORT_WIZARD_AUTOMATIC_IN_SCOPE_DETECT_MUTATION = gql`
  mutation automaticInScopeDetection($wizardId: Int!) {
    automaticInScopeDetection(wizardId: $wizardId) {
      Comment
      TotalRows
      Rows {
        id
        inScope
        poNumber
        vesselName
        vesselImo
        equipmentManufacturer
        equipmentDescription
        productCode
        productName
        quantity
        containsPCHM
        containsCHM
        poDate
        equipmentId
        productDescription
        general
        orderStatus
        mdSDoCStatus
        formType
        externalCategory
        supplierRequestName
        supplierRequestEmailAddress
        wizardId
        doNotExecuteWorkflow
        matching
        unit
      }
    }
  }
`;

export const IMPORT_PO_VALIDATE_IN_SCOPE_MUTATION = gql`
  mutation importPoValidateInScope(
    $wizardId: Int!
    $inScopeValidationFilters: [InScopeValidationFilter!]!
    $page: Int!
    $limit: Int!
    $InScopeValidationOrder: InScopeValidationOrder!
    $filterScope: Int!
    $filterMatching: Int!
  ) {
    importPoValidateInScope(
      wizardId: $wizardId
      inScopeValidationFilters: $inScopeValidationFilters
      page: $page
      limit: $limit
      InScopeValidationOrder: $InScopeValidationOrder
      filterScope: $filterScope
      filterMatching: $filterMatching
    ) {
      Comment
      TotalRows
      Rows {
        id
        inScope
        poNumber
        vesselName
        vesselImo
        equipmentManufacturer
        equipmentDescription
        productCode
        productName
        quantity
        containsPCHM
        containsCHM
        poDate
        equipmentId
        productDescription
        general
        orderStatus
        mdSDoCStatus
        formType
        externalCategory
        supplierRequestName
        supplierRequestEmailAddress
        wizardId
        doNotExecuteWorkflow
        matching
      }
    }
  }
`;

export const SET_PO_ITEMS_SCOPE_MUTATION = gql`
  mutation setScope($selectedPoRequestIds: [Int!]!, $scope: Boolean!) {
    setScope(selectedPoRequestIds: $selectedPoRequestIds, scope: $scope)
  }
`;

export const SET_PO_ITEMS_EXECUTE_WF_MUTATION = gql`
  mutation setDoNotExecuteWorkflow(
    $selectedPoRequestIds: [Int!]!
    $doNotExecuteWorkflow: Boolean!
  ) {
    setDoNotExecuteWorkflow(
      selectedPoRequestIds: $selectedPoRequestIds
      doNotExecuteWorkflow: $doNotExecuteWorkflow
    )
  }
`;

export const IMPORT_WIZARD_REPORT_MUTATION = gql`
  mutation importWizardReport($wizardId: Int!, $purchaseOrdersToDelete: [PurchaseOrdersToDeleteInput!]!) {
    importWizardReport(wizardId: $wizardId, purchaseOrdersToDelete: $purchaseOrdersToDelete) {
      WizardId
      TotalRecords
      TotalVessels
      TotalSuppliersAlreadyRegisteredFound
      TotalNewSuppliersFound
      TotalInScopes
      TotalOutOfScopes
    }
  }
`;

export const SUBMIT_SUPPLIER_REQUESTS_MUTATION = gql`
  mutation submitSupplierRequests($wizardId: Int!) {
    submitSupplierRequests(wizardId: $wizardId)
  }
`;

export const SUBMIT_SUPPLIER_REQUESTS_JOB_MUTATION = gql`
  mutation submitSupplierRequestsJob($wizardId: Int!) {
    submitSupplierRequestsJob(wizardId: $wizardId)
  }
`;

export const SUBMIT_SUPPLIER_REQUESTS_BACKGROUND_JOB_MUTATION = gql`
  mutation submitSupplierRequestsBackgroundJob($wizardId: Int!) {
    submitSupplierRequestsBackgroundJob(wizardId: $wizardId)
  }
`;

export const MOVE_PO_TO_NEXT_STATE_MUTATION = gql`
  mutation movePoToNextState(
    $poId: Int!
    $supplierRequestId: String!
    $state: Int!
  ) {
    movePoToNextState(
      poId: $poId
      supplierRequestId: $supplierRequestId
      state: $state
    )
  }
`;

export const MOVE_PO_TO_NEXT_STATE_MASSIVE_MUTATION = gql`
mutation movePosToNextStateMassive($approvePos: [ApprovePos!]!, $state: Int!) {
  movePosToNextStateMassive(approvePos: $approvePos, state: $state)
}
`;

export const UPDATE_POSITION_COORDNATES_MUTATION = gql`
mutation updatePositionCoordinates(
  $id: Int!
  $x: Float!
  $y: Float!

){
  updatePositionCoordinates(
    id: $id
    x: $x
    y: $y

    )
}
`;


export const MOVE_PO_ITEMS_TO_NEXT_STATE = gql`
  mutation movePoItemsToNextState(
    $poId: Int!
    $supplierRequestId: String!
    $state: Int!
    $supplierReferenceNumber: String!
    $poDate: String!
    $supplierId: Int!
    $vesselImo: String!
  ){
    movePoItemsToNextState(
      poId: $poId
      supplierRequestId: $supplierRequestId
      state: $state
      supplierReferenceNumber: $supplierReferenceNumber
      poDate: $poDate
      supplierId: $supplierId
      vesselImo : $vesselImo
    )
  }
`;

export const MOVE_POS_TO_NEXT_STATE_MUTATION = gql`
  mutation movePosToNextState(
    $approvePos: [ApprovePos!]!
    $state: Int!
    $supplierId: Int!
    $poDate: String!
    $vesselImo: String!
    $supplierReferenceNumber: String!

    ) {
    movePosToNextState(
      approvePos: $approvePos
      state: $state
      supplierId: $supplierId
      poDate: $poDate
      vesselImo: $vesselImo
      supplierReferenceNumber: $supplierReferenceNumber
    )
  }
`;

export const MAKE_SUPPLIER_REQUEST_GREEN_MUTATION = gql`
  mutation makeSupplierRequestGreen(
    $supplierRequestIdsArray: [String!]!
    $nextState: Int!
    $greenForm: CreatePoRequestDocument!
  ) {
    makeSupplierRequestGreen(
      supplierRequestIdsArray: $supplierRequestIdsArray
      nextState: $nextState
      greenForm: $greenForm
    )
  }
`;

export const MAKE_PURCHASE_ORDER_ITEM_GREEN_MUTATION = gql`
  mutation makePurchaseOrderItemGreen(
    $poItemIds: [Int!]!
    $nextState: Int!
    $greenForm: CreatePoRequestDocument!
  ) {
    makePurchaseOrderItemGreen(
      poItemIds: $poItemIds
      nextState: $nextState
      greenForm: $greenForm
    )
  }
`;

export const SMART_AGENT_SUBMIT_JOB = gql`
  mutation smartAgentSubmitJob($issuerId: Int!) {
    smartAgentSubmitJob(issuerId: $issuerId)
  }
`;

export const MAKE_PO_FORM_MOVE_NEXT_STATE_MUTATION = gql`
  mutation makePoFormMoveNextStateJob(
    $supplierRequests: [GreenFormSelectedReq!]!
    $nextState: Int!
    $greenForm: CreatePoRequestDocument!
  ) {
    makePoFormMoveNextStateJob(
      supplierRequests: $supplierRequests
      nextState: $nextState
      greenForm: $greenForm
    )
  }
`;

export const GENERATE_DOC_AND_MOVE_NEXT_STATE_JOB = gql`
  mutation generatePoDocAndMoveNextStateJob(
    $supplierRequests: [GreenFormSelectedReq!]!
    $nextState: Int!
    $greenForm: CreatePoRequestDocument!
    $isResubmit: Boolean!
  ) {
    generatePoDocAndMoveNextStateJob(
      supplierRequests: $supplierRequests
      nextState: $nextState
      greenForm: $greenForm
      isResubmit: $isResubmit
    )
  }
`;

export const REMOVE_SUPPLIERS_WITH_MATERIALS_MUTATION = gql`
  mutation removeSupplierInventoryWithMaterials($id: Int!) {
    removeSupplierInventoryWithMaterials(id: $id)
  }
`;

export const REMOVE_SELECTED_SUPPLIERS_WITH_MATERIALS_MUTATION = gql`
  mutation removeSelectedSupplierInventoriesWithMaterials($ids: [Int!]!) {
    removeSelectedSupplierInventoriesWithMaterials(ids: $ids)
  }
`;

export const REMOVE_SUPPLIERS_INVENTORY_MATERIAL_MUTATION = gql`
  mutation removeSupplierInventoryMaterial($id: Int!) {
    removeSupplierInventoryMaterial(id: $id)
  }
`;

export const CREATE_SUPPLIER_INVENTORY_MATERIAL_MUTATION = gql`
  mutation createSupplierInventoryMaterial(
    $createSupplierInventoryMaterialInput: CreateSupplierInventoryMaterialInput!
  ) {
    createSupplierInventoryMaterial(
      createSupplierInventoryMaterialInput: $createSupplierInventoryMaterialInput
    ) {
      id
      name
      thresholdValue
      presentAboveThresholdValue
      unit
    }
  }
`;

export const CREATE_SUPPLIER_INVENTORY = gql`
  mutation addToSupplierInventory($sendNewItemInput: CreateSupplierInventoryInput!){
    addToSupplierInventory(createSupplierInventoryInput: $sendNewItemInput){
      productName
      productCode
      containsHazMat

      supplierId
      productInformation
    }
  }
`;

export const IMPORT_SUPPLIER_INVENTORY_MAP_EXCEL_HEADERS_MUTATION = gql`
  mutation importSupplierInventoryMapExcelHeaders($file: Upload!) {
    importSupplierInventoryMapExcelHeaders(file: $file) {
      productName
      productCode
      containsHazMat
      poItemIdReferenceId
      supplierId
      productInformation
      itemUnit
      supplierInventoryMaterial {
        name
        category
        thresholdValue
        presentAboveThresholdValue
        mass
        unit
        comment
      }
    }
  }
`;

export const CREATE_SUPPLIER_INVENTORIES_MUTATION = gql`
  mutation createSupplierInventories(
    $createSupplierInventoryInputArray: [CreateSupplierInventoryInput!]!
  ) {
    createSupplierInventories(
      createSupplierInventoryInputArray: $createSupplierInventoryInputArray
    )
  }
`;
// ----------------------------------------------------------- FOLLOWINGS ------------------------------------------------------------------------------------------------
export const FOLLOW_ENTITY_MUTATION = gql`
  mutation followEntity($followType: Int!, $entityReferenceId: Int!) {
    followEntity(followType: $followType, entityReferenceId: $entityReferenceId)
  }
`;

export const UNFOLLOW_ENTITY_MUTATION = gql`
  mutation unFollowEntity($followType: Int!, $entityReferenceId: Int!) {
    unFollowEntity(
      followType: $followType
      entityReferenceId: $entityReferenceId
    )
  }
`;

export const UPDATE_FOLLOWED_ITEM_MUTATION = gql`
  mutation updateFollowed(
    $updateFollowSystemEntityInput: UpdateFollowSystemEntityInput!
  ) {
    updateFollowed(
      updateFollowSystemEntityInput: $updateFollowSystemEntityInput
    )
  }
`;

export const REGISTER_APP_USER_SUPPLIER_MUTATION = gql`
  mutation registerAppUserSupplier(
    $createSupplierInput: CreateSupplierInput!
    $createAppUserInput: CreateAppUserInput!
  ) {
    registerAppUserSupplier(
      createSupplierInput: $createSupplierInput
      createAppUserInput: $createAppUserInput
    )
  }
`;

export const UPDATE_APP_USER_LAST_LOGIN = gql`
  mutation updateLastLogin {
    updateLastLogin
  }
`;

// ----------------------------------------------------------- SHARE REPORTS ------------------------------------------------------------------------------------------------

export const SHARE_IHM_REPORT_OF_SHIP_OWNER_MUTATION = gql`
  mutation shareIhmReportOfShipOwnerUser($cc: [String!]!, $shipOwnerId: Int!) {
    shareIhmReportOfShipOwnerUser(cc: $cc, shipOwnerId: $shipOwnerId)
  }
`;

export const SHARE_MD_REPORT_OF_SHIP_OWNER_MUTATION = gql`
  mutation shareMdReportOfShipOwnerUser($cc: [String!]!, $shipOwnerId: Int!) {
    shareMdReportOfShipOwnerUser(cc: $cc, shipOwnerId: $shipOwnerId)
  }
`;

export const SHARE_MD_REPORT_OF_SHIP_OWNER_VESSEL_MUTATION = gql`
  mutation shareMdReportOfShipOwnerVesselUser(
    $cc: [String!]!
    $shipOwnerId: Int!
    $vesselId: Int!
  ) {
    shareMdReportOfShipOwnerVesselUser(
      cc: $cc
      shipOwnerId: $shipOwnerId
      vesselId: $vesselId
    )
  }
`;

export const SEND_VESSEL_IHM_EMAIL_MUTATION = gql`
  mutation sendEmailIhmforVessel(
    $vesselId: Int!
  ) {
    sendEmailIhmforVessel(
      vesselId: $vesselId
    )
  }
`;

export const SEND_REMINDER_TO_SUPPLIER_EMAIL_MUTATION = gql`
  mutation sendReminderForPendingIhmDocuments(
    $supplierId: Int!

  ) {
    sendReminderForPendingIhmDocuments(
      supplierId: $supplierId
    )
  }
`;

export const RESET_DATE_OF_IHM_ITEM_OF_VESSEL = gql`
  mutation resetIhmItem(
    $vesselId: Int!
  ){
    resetIhmItem(
      id: $vesselId
    )
  }
`;


export const MD_DOC_MUTATION = gql`
  mutation mdToPrint(
    $sendMdInput: SendMdInput!
    $supplierId: Int!
  ){
    mdToPrint(
      sendMdInput: $sendMdInput
      supplierId: $supplierId
    ){
      file
      name
    }
  }
`;

export const MD_DOC_FOR_PREVIEW_MUTATION = gql`
  mutation mdToPreview(
    $poItemId: Int!
  ){
    mdToPreview(
      poItemId: $poItemId
    ){
      file
      name
    }
  }
`;


// ----------------------------------------------------SPARE PARTS---------------------------------------------------

export const DELETE_SPARE_PART = gql`
  mutation deleteSparePart(
    $id: Int!
  ){
    deleteSparePart(
      id: $id
    )
  }
`;

export const UPDATE_SPARE_PART = gql`
  mutation updateSparePart(
    $id: Int!
    $productName: String!
    $itemNumber: String!
    $description: String!
    $sparePartcategory: [Int!]!
    $alwaysIncluded: Boolean!
    $recommendedQuantity: Int!
  ){
    updateSparePart(
      id: $id
      recommendedQuantity: $recommendedQuantity
      alwaysIncluded: $alwaysIncluded
      sparePartcategory: $sparePartcategory
      description: $description
      itemNumber: $itemNumber
      productName: $productName
    ) 
  }
`;

export const CREATE_SPARE_PART = gql`
  mutation createSparePart($createSparePartInput: CreateSparePartInput!){
    createSparePart(createSparePartInput: $createSparePartInput)
  }
`;


//--------------------------------------------SP COMPONENTS----------------------------------------

export const UPLOAD_SP_COMPONENT_THUMBNAIL_IMAGE_MUTATION = gql`
  mutation uploadSpComponentThumbnailImage(
    $file: Upload!
    $spComponentId: Int!
  ) {
    uploadSpComponentThumbnailImage(
      file: $file
      spComponentId: $spComponentId
    )
  }
`;

export const UPLOAD_SP_COMPONENT_DRAWING_IMAGE_MUTATION = gql`
  mutation uploadSpComponentDrawingImage(
    $file: Upload!
    $spComponentId: Int!
  ) {
    uploadSpComponentDrawingImage(
      file: $file
      spComponentId: $spComponentId
    )
  }
`;

export const CREATE_SP_COMPONENT = gql`
  mutation createSpComponent(
    $createSpComponentInput: CreateSpComponentInput!
  ){
    createSpComponent(createSpComponentInput: $createSpComponentInput){
      id
    }
  }
`;

export const DELETE_SP_COMPONENT = gql`
  mutation deleteSpComponent(
    $id: Int!
  ){
    deleteSpComponent(
      id: $id
    )
  }
`;

export const UPDATE_SP_COMPONENT = gql`
  mutation updateSpComponent(
    $id: Int!
    $name: String!
    $troManufacturer: String!
    $filterManufacturer: String!
    $definition: String!
    $hasMap: Boolean!
  ){
    updateSpComponent(
      id: $id
      name: $name
      troManufacturer: $troManufacturer
      filterManufacturer: $filterManufacturer
      definition: $definition
      hasMap: $hasMap
    )
  }
`;

export const DELETE_SPARE_PART_OF_COMPONENT = gql`
  mutation deleteSparePartOfComponent($id: Int!){
    deleteSparePartOfComponent(id: $id)
  }
`;

export const ADD_SELECTED_SPARE_PARTS_TO_COMPONENT = gql`
  mutation addSelectedSparePartsToComponent($componentId: Int!, $ids: [Int!]!){
    addSelectedSparePartsToComponent(componentId: $componentId, ids: $ids)
  }
`;

export const ADD_NEW_POSITION_FOR_SPARE_PART_OF_COMPONENT = gql`
  mutation addNewPositionForSparePartOfComponent($spSparePartsComponentId: Int!, $lon: Float!, $lat: Float!){
    addNewPositionForSparePartOfComponent(spSparePartsComponentId: $spSparePartsComponentId, lon: $lon, lat: $lat)
  }
`;

export const REMOVE_POSITION_FOR_SPARE_PART_OF_COMPONENT = gql`
  mutation removePositionForSparePartOfComponent($id: Int!){
    removePositionForSparePartOfComponent(id: $id)
  }
`;

export const DELETE_COMPONENT_OF_SYSTEM = gql`
  mutation deleteComponentOfSystem($id: Int!){
    deleteComponentOfSystem(id: $id)
  }
`;

export const ADD_COMPONENTS_TO_SYSTEM = gql`
  mutation addComponentToSystem($capacity: String!, $ex: String!, $ids: [Int!]!){
    addComponentToSystem(capacity: $capacity, ex: $ex, ids: $ids )
  }
`;

export const ADD_INSTALLATION_ID_OF_SPAREPART_COMPONENT = gql`
  mutation setInstallationIdOfSparepartComponent($id: Int!, $installationId: Int!){
    setInstallationIdOfSparepartComponent(id: $id, installationId: $installationId)
  }
`;

export const UPDATE_QUOTATION_IS_PROCESSED_STATUS = gql`
  mutation updateQuotationIsProcessedStatus($id: Int!, $quotationIsProcessedStatus: Boolean!){
    updateQuotationIsProcessedStatus(id: $id, quotationIsProcessedStatus: $quotationIsProcessedStatus)
  }
`;

export const RESET_SUPPLIER_COMPLIANCE_FOR_SHIPOWNER = gql`
  mutation resetSupplierComplianceForShipOwner($shipOwnerId: Int!){
    resetSupplierComplianceForShipOwner(shipOwnerId: $shipOwnerId)
  }
`;

export const MERGE_IHM_VERSIONING = gql`
  mutation mergeIhmVersioning(
  $ids: [Int!]!
  $comment: String!
  $versionNumber: Int!
  $dateOfVersioningFormatted: String!
  ){
    mergeIhmVersioning(
    ids: $ids
    comment: $comment
    versionNumber: $versionNumber
    dateOfVersioningFormatted: $dateOfVersioningFormatted
    )
  }
`;

export const RESEND_VESSEL_IHM_EMAIL_MUTATION = gql`
  mutation resendEmailIhmforVessel(
    $vesselId: Int!
    $shipOwnerIhmShareEmailReportId: Int!
    $batchId: String!
  ){
    resendEmailIhmforVessel(
      vesselId: $vesselId
      shipOwnerIhmShareEmailReportId: $shipOwnerIhmShareEmailReportId
      batchId: $batchId
    )
  }
`;

export const START_A_NEW_RESET_COMPLIANCE_PROCESS = gql`
  mutation startANewResetComplianceProcess($shipOwnerId: Int!){
    startANewResetComplianceProcess(shipOwnerId: $shipOwnerId)
  }
`;

export const EDIT_REMARKS_OF_SELECTED_PURCHASE_ORDER_MUTATION = gql`
  mutation editRemarksOfSelectedPurchaseOrder($purchaseOrderId: Int!, $remarks: String!){
    editRemarksOfSelectedPurchaseOrder(purchaseOrderId: $purchaseOrderId, remarks: $remarks)
  }
`;

export const REMOVE_SELECTED_UPLOADED_DOCUMENTS = gql`
  mutation removeSelectedUploadedDocuments($ids: [Int!]!){
    removeSelectedUploadedDocuments(ids: $ids)
  }
`;

export const MOVE_PO_DOC_AND_MOVE_NEXT_STATE_JOB = gql`
  mutation movePoDocAndMoveNextStateJob(
    $supplierRequests: [GreenFormSelectedReq!]!
    $nextState: Int!
  ){
    movePoDocAndMoveNextStateJob(
      supplierRequests: $supplierRequests
      nextState: $nextState
    )
  }
`;


export const UPDATE_SUPPLIER_GENERAL_DATA = gql`
  mutation updateProductOfSupplier($updateSupplierGeneralDataInput: UpdateSupplierGeneralDataInput!){
    updateProductOfSupplier(updateSupplierGeneralDataInput: $updateSupplierGeneralDataInput)
  }
`;
