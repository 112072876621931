import { useEffect, useState } from "react";
// material
import { useLazyQuery, useQuery } from "@apollo/client";
import { LoadingButton } from "@mui/lab";
import { Box, Container, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  DataGrid,
  GridColumns,
  GridRowModel,
  GridSelectionModel,
} from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ShipOwnerDTO } from "../../@types/ShipOwner";
import { VesselDTO } from "../../@types/Vessel";
import {
  GET_IHM_STORED_ITEMS,
  GET_SHIPOWNERS_QUERY,
  GET_VESSELS_BY_SHIPOWNER_QUERY,
} from "../../graphQL/Queries";
import { parseDate } from "../../utils/formatTime";
import ShipOwnerAndVesselSelects from "../ShipOwnerAndVesselSelects";
import { TableListToolbar } from "../table";
import InstallIhmMapperModal from "../map/installIhm/InstallIhmMapperModal";
import { ShipOwnerVesselDto } from "../../@types/ShipOwnerVessel";

// -------------------------------- Styles ---------------------------------------
const useStyles = makeStyles(() => ({
  customStyle: {
    "& .MuiDataGrid-toolbarContainer": {
      background: "#ffffff",
    },
  },
  panel: {
    "& .MuiNativeSelect-select": {
      backgroundColor: "red",
      color: "red",
    },
  },
  actionBtn: {
    "&.MuiLoadingButton-root": {
      backgroundColor: "#0079C1",
      borderRadius: "25px",
    },
  },
}));

// Define Queries
const getIhmsQuery = (
  searchToken: string,
  selectedShipownerVessel: ShipOwnerVesselDto | undefined,
  limit: number,
  page: number
) =>
  useQuery(GET_IHM_STORED_ITEMS, {
    variables: {
      searchToken: searchToken,
      shipOwnerId: (selectedShipownerVessel && selectedShipownerVessel?.shipOwnerDto && selectedShipownerVessel?.shipOwnerDto.id) ? parseInt(selectedShipownerVessel.shipOwnerDto.id) : 0,
      vesselId: (selectedShipownerVessel && selectedShipownerVessel.vesselDto && selectedShipownerVessel.vesselDto.id) ? parseInt(selectedShipownerVessel.vesselDto.id) : 0,
      limit: limit,
      page: page,
    },
    skip: selectedShipownerVessel == undefined,
    onError(err) {
      toast.configure();
      toast.error(`Error: ${err.message}`);
    },
    fetchPolicy: "network-only",
  });

// ------------------------------------------------------------------------------------------------------------------------
export default function StoredIhmsTable() {
  // native props
  const classesNew = useStyles();
  const navigate = useNavigate();
  const [searchToken, setSearchToken] = useState("");
  const [dataTableRows, setDataTableRows] = useState<GridRowModel[]>([]);
  const [ihmIdToInstall, setIhmIdToInstall] = useState<number>();
  const [isIhmMapperModalOpen, setIsIhmMapperModalOpen] = useState(false);

  // dataGrid props
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(20);
  const [selectedShipOwnerIdAndVesselId, setSelectedShipOwnerIdAndVesselId] = useState<ShipOwnerVesselDto | undefined>();

  // Use Queries
  

  const [getIhmsLazyQuery, { loading: ihmsLoading, data: ihmsData }] = useLazyQuery(
    GET_IHM_STORED_ITEMS,
    {
      variables: {
        searchToken: searchToken,
        shipOwnerId: (selectedShipOwnerIdAndVesselId && selectedShipOwnerIdAndVesselId?.shipOwnerDto && selectedShipOwnerIdAndVesselId?.shipOwnerDto.id) ? parseInt(selectedShipOwnerIdAndVesselId.shipOwnerDto.id) : 0,
        vesselId: (selectedShipOwnerIdAndVesselId && selectedShipOwnerIdAndVesselId.vesselDto && selectedShipOwnerIdAndVesselId.vesselDto.id) ? parseInt(selectedShipOwnerIdAndVesselId.vesselDto.id) : 0,
        limit: rowsPerPage,
        page: page,
      }
    }
  );


useEffect(()=>{
  if(selectedShipOwnerIdAndVesselId && selectedShipOwnerIdAndVesselId != undefined){
    getIhmsLazyQuery();
  }
},[selectedShipOwnerIdAndVesselId]);


  // --------------------------------- hooks -------------------------------------------------
  useEffect(() => {
    if (
      ihmsData &&
      ihmsData.findIhmStoredItems
    ) {
      const tableRowsArray: GridRowModel[] = [];

      for (const storedIhm of ihmsData.findIhmStoredItems) {
        const dataTableRow: GridRowModel = {
          id: storedIhm.ihmId,
        };

        dataTableRow.vesselName = storedIhm.vesselName;
        dataTableRow.vesselImo = storedIhm.vesselImo;
        dataTableRow.inventorySubPart = `Sub Part ${storedIhm.inventorySubPart}`;
        dataTableRow.equipmentDescription = storedIhm.equipmentDescription;
        dataTableRow.productName = storedIhm.productName;
        dataTableRow.storedQuantity = storedIhm.storedQuantity;
        dataTableRow.location = storedIhm.location;
        dataTableRow.referenceNumber = storedIhm.referenceNumber;

        dataTableRow.dateAdded =
          typeof storedIhm.dateAdded === "string" &&
            parseDate(storedIhm.dateAdded) !== "Invalid Date"
            ? parseDate(storedIhm.dateAdded)
            : storedIhm.dateAdded;

        tableRowsArray.push(dataTableRow);
      }

      setDataTableRows(tableRowsArray);
    }
  }, [ihmsData]);

  // --------------------------------- handlers -----------------------------------------------
  const handleFilterByName = (filterName: string) => {
    setSearchToken(filterName);
    setPage(0);
  };

  // --------------------------------- Table Setup -----------------------------------------------
  const TABLE_HEAD: GridColumns = [
    {
      field: "vesselName",
      headerName: "Vessel Name",
      width: 180,
      type: "string",
      sortable: false,
    },
    {
      field: "vesselImo",
      headerName: "Vessel IMO",
      width: 180,
      type: "string",
      sortable: false,
    },
    {
      field: "inventorySubPart",
      headerName: "IHM Sub Part",
      width: 180,
      type: "string",
      sortable: false,
    },
    {
      field: "equipmentDescription",
      headerName: "Description",
      width: 180,
      type: "string",
      sortable: false,
    },
    {
      field: "productName",
      headerName: "Component / Item",
      width: 180,
      type: "string",
      sortable: false,
    },
    {
      field: "storedQuantity",
      headerName: "Stored Quantity",
      width: 180,
      type: "string",
      sortable: false,
    },
    {
      field: "location",
      headerName: "Location",
      width: 180,
      type: "string",
      sortable: false,
    },
    {
      field: "referenceNumber",
      headerName: "Reference Number",
      width: 180,
      type: "string",
      sortable: false,
    },
    {
      field: "dateAdded",
      headerName: "Date Added",
      width: 180,
      type: "string",
      sortable: false,
    },
  ];

  // ------------------------------------------------------------------------------------------
  return (
    <Container maxWidth={"xl"}>
      <Grid
        container
        direction="row"
        alignItems="center"
        spacing={2}
        sx={{ mt: 0.5, justifyContent: "space-between" }}
      >
        <Box sx={{ flex: "1" }}>
          <TableListToolbar
            numSelected={0}
            filterName={searchToken}
            onFilterName={handleFilterByName}
          />
        </Box>
        <Box sx={{ flex: "1" }}>
          <ShipOwnerAndVesselSelects
            loadFromSessionShipOwnerAndVessels={true}
            selectedShipOwnerId={0}
            selectedVesselId={0}
            setSelectedShipOwnerIdAndVesselId={setSelectedShipOwnerIdAndVesselId}
            manualSetAndFindShipOwnerAndVessel={false}
            stateOfLoadingFind={false}
            purchaseOrderTableAutoLoad={false}
          />
        </Box>
        <Box sx={{ flex: "1", display: "flex", justifyContent: "flex-end" }}>
          <LoadingButton
            variant="contained"
            disabled={selectionModel.length != 1}
            onClick={() => {
              setIhmIdToInstall(
                selectionModel[0] ? parseInt(`${selectionModel[0]}`) : undefined
              );
              setIsIhmMapperModalOpen(true);
            }}
            className={classesNew.actionBtn}
          >
            Install
          </LoadingButton>
          <LoadingButton
            sx={{ ml: 2 }}
            variant="contained"
            disabled={selectionModel.length != 1}
            onClick={() => {
              navigate(`/dashboard/ihms/${selectionModel[0]}/edit`);
            }}
            className={classesNew.actionBtn}
          >
            View
          </LoadingButton>
        </Box>
      </Grid>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mt: 4,
        }}
      >
        <Box
          sx={{
            height: 550,
            width: 1,
            "& .super-app-theme--2": {
              bgcolor: () => "rgba(91, 229, 132, 0.15)",
            },
            "& .super-app-theme--1": {
              bgcolor: () => "rgba(255, 244, 165, 0.5)",
            },
            marginTop: 4,
          }}
        >
          <DataGrid
            columns={TABLE_HEAD}
            rows={dataTableRows}
            className={classesNew.customStyle}
            loading={ihmsLoading}
            disableColumnFilter
            // --------------------selection props
            disableSelectionOnClick
            checkboxSelection
            onSelectionModelChange={(newSelectionModel) =>
              setSelectionModel(newSelectionModel)
            }
            selectionModel={selectionModel}
            // --------------------pagination props
            pagination
            paginationMode="server"
            rowCount={dataTableRows.length}
            rowsPerPageOptions={[20, 50, 100]}
            pageSize={rowsPerPage}
            onPageSizeChange={(pageSize) => setRowsPerPage(pageSize)}
            onPageChange={(newPage) => {
              setPage(newPage);
            }}
          />
        </Box>
      </Box>

      {ihmIdToInstall && (
        <InstallIhmMapperModal
          isOpenPreview={isIhmMapperModalOpen}
          onClosePreview={() => {
            setIsIhmMapperModalOpen(false);
          }}
          ihmId={ihmIdToInstall}
        />
      )}
    </Container>
  );
}
